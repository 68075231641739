import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import { Link, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar'
import Moment from 'react-moment';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
Moment.globalFormat = 'D MMM YYYY hh:mm A';

export default function Service() {
   // Base Url
   const url = process.env.REACT_APP_BASE_URL;
   // Base Url
  const { slug } = useParams();
  var agents_carousel = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      }, // Tablet
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Large Mobile
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Small Mobile
    ],
  };
  var settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1169,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      }, // Medium Device
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      }, // Tablet
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      }, // Large Mobile
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Small Mobile
    ],
  };
 

  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoadedServiceDetails] = useState(true);
  const [serviceDetails, setItemsServiceDetails] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemsServiceDetails = async () => {
      setProgress(progress + 50);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/get_service_by_slug/${slug}`)
        .then(function (resultServiceDetails) {
          setProgress(80);
          setItemsServiceDetails(resultServiceDetails.data);
          setIsLoadedServiceDetails(false);
          setProgress(100);
        })
        .catch(function (error) {
          setIsLoadedServiceDetails(true);
        });
    };
    getItemsServiceDetails();
  }, []);
  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);
  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
      <LoadingBar
        color='#f11946'
        progress={progress}
        height={3}
        onLoaderFinished={() => setProgress(0)}
      />
        <div
          class="breadcrumbs-area bread-bg-1 bg-opacity-black-70"
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="breadcrumbs">
                  <h2 class="breadcrumbs-title">Service</h2>
                  <ul class="breadcrumbs-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/services">Service</Link>
                    </li>
                    <li>{serviceDetails.service.service_title}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="page-content" class="page-wrapper">
          <div class="about-sheltek-area ptb-115">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-12 order-md-2">
                  <div class="section-title mb-30">
                    <h2>{serviceDetails.service.service_title}</h2>
                  </div>
                  <div class="about-sheltek-info">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: serviceDetails.service.service_description,
                      }}
                    />
                  </div>
                </div>
                <div class="col-md-6 col-12 order-md-1">
                  <div class="about-image">
                    <img src={url + serviceDetails.service.service_image} alt={serviceDetails.service.service_title} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="featured-flat-area pb-60">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="section-title-2 text-center">
                    <h2>{serviceDetails.section_title.property_title}</h2>
                    <p>{serviceDetails.section_title.property_sub_title}</p>
                  </div>
                </div>
              </div>
              <div class="featured-flat">
                <div class="row">
                  {serviceDetails.all_properties.map((properties, i) => (
                    <div className="col-lg-4 col-md-6 col-12" key={i}>
                    <div className="flat-item">
                      <div className="flat-item-image">
                        {properties.pr_sale_or_rent == 1 ?
                        <span className="for-sale">For Sale</span>
                        : properties.pr_sale_or_rent == 2 ?
                        <span className="for-sale rent">For Rent</span>
                        :
                        <span className="for-sale rent">For Long Lease</span>
                        }
                        <Link to={'/propertie-details/'+properties.pr_slug}>
                          <Carousel showArrows={false} showIndicators={false} swipeable={true} showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true}>
                            {properties.image.map((all_img, i) => (
                              <div key={'123'+i}>
                                  <img src={url+all_img.pr_img} />
                              </div>
                            ))}
                          </Carousel>
                        </Link>
                        <div className="flat-link">
                          <Link to={'/propertie-details/'+properties.pr_slug}>More Details</Link>
                        </div>
                        <ul className="flat-desc">
                          {properties.area  ?
                            <li>
                              <img src="/front/images/icons/4.png" alt={properties.area} />
                              <span>{properties.area}</span>
                            </li>
                          :
                          <li></li>
                          }
                          {properties.bedroom > 0  ?
                            <li>
                              <img src="/front/images/icons/5.png" alt="Bedroom" />
                              <span>{properties.bedroom}</span>
                            </li>
                          :
                          <li></li>
                          }
                          {properties.bathroom > 0  ?
                            <li>
                              <img src="/front/images/icons/6.png" alt="BathRoom" />
                              <span>{properties.bathroom}</span>
                            </li>
                          :
                          <li></li>
                          }
                        </ul>
                      </div>
                      <div className="flat-item-info">
                        <div className="flat-title-price">
                          <h5>
                            <Link to={'/propertie-details/'+properties.pr_slug}>
                             {properties.pr_name}
                            </Link>
                          </h5>
                          <span className="price">{numberFormat(properties.price)}</span>
                        </div>
                        <p>
                          <img src="/front/images/icons/location.png" alt="" />
                          {properties.pr_address}
                        </p>
                      </div>
                    </div>
                  </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div class="booking-area bg-1 call-to-bg plr-140 pt-75" style={{ backgroundImage: `url(${url + serviceDetails.booking_section.bg_image})` }}>
            <div class="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>{serviceDetails.booking_section.one_st_title}</h3>
                    <h2 className="h1">{serviceDetails.booking_section.one_st_sub_title}</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="booking-conternt clearfix">
                    <div className="book-house text-white">
                      <h2>{serviceDetails.booking_section.two_nd_title}</h2>
                      <h2 className="h5">{serviceDetails.booking_section.two_nd_sub_title}</h2>
                    </div>
                    <div className="booking-imgae">
                      <img src={url + serviceDetails.booking_section.image} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-agents-area pt-115 pb-55">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title-2 text-center">
                    <h2>{serviceDetails.section_title.agent_title}</h2>
                    <p>{serviceDetails.section_title.agent_sub_title}</p>
                  </div>
                </div>
              </div>
              <div className="our-agents">
                <div className="row">
                  <div className="col-12">
                    <Slider {...agents_carousel}>
                    {serviceDetails.all_agent.map((agent, i) => (
                        <div className="col-12" key={agent.id+i}>
                          <div className="single-agent">
                            <div className="agent-image">
                              <img src={url+agent.image} alt={agent.name} />
                            </div>
                            <div className="agent-info">
                              <div className="agent-name">
                                <h5>
                                  <Link to={'/agent-details/'+agent.slug}>
                                  {agent.name}
                                  </Link>
                                </h5>
                                <p>{agent.position}</p>
                              </div>
                            </div>
                            <div className="agent-info-hover">
                              <div className="agent-name">
                                <h5>
                                  <Link to={'/agent-details/'+agent.slug}>
                                    {agent.name}
                                  </Link>
                                </h5>
                                <p>{agent.position}</p>
                              </div>
                              <ul className="agent-address">
                                <li>
                                  <img
                                    src="/front/images/icons/phone-2.png"
                                    alt=""
                                  />{agent.mobile}
                                </li>
                                <li>
                                  <img
                                    src="/front/images/icons/mail-close.png"
                                    alt=""
                                  />{agent.email}
                                </li>
                              </ul>
                              <ul className="social-media">
                                <li>
                                  <a href={agent.fb_url}>
                                    <i
                                      className="fa fa-facebook"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.tw_url}>
                                    <i
                                      className="fa fa-twitter"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.linkdin_url}>
                                    <i
                                      className="fa fa-linkedin"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.telegram_url}>
                                    <i
                                      className="fa fa-telegram"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                      
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="brand-area pb-115 d-none">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Slider {...settings}>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/1.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/2.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/3.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/4.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/5.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/1.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/4.png" alt="" />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div class="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-12">
                  <div class="section-title text-white">
                    <h3>SUBSCRIBE</h3>
                    <h2 class="h1">NEWSLETTER</h2>
                  </div>
                </div>
                <div class="col-lg-9 col-md-8 col-12">
                  <div class="subscribe">
                    <form action="#">
                      <input
                        type="text"
                        name="subscribe"
                        placeholder="Enter yur email here..."
                      />
                      <button type="submit" value="send">
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
