import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { firebase, auth } from './firebase';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LoadingBar from 'react-top-loading-bar'
Moment.globalFormat = 'D MMM YYYY hh:mm A';

export default function BlogDetails() {
  // Base Url
  const url = process.env.REACT_APP_BASE_URL;
  const MySwal = withReactContent(Swal)
  // Base Url
  const { slug } = useParams();
  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoadedServiceDetails] = useState(true);
  const [page, setItemsServiceDetails] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemsServiceDetails = async () => {
      setProgress(progress + 40);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/get_blog_by_slug/${slug}`)
        .then(function (resultServiceDetails) {
          setProgress(80);
          setItemsServiceDetails(resultServiceDetails.data);
          setIsLoadedServiceDetails(false);
          setProgress(100);
        })
        .catch(function (error) {
          setIsLoadedServiceDetails(true);
        });
    };
    getItemsServiceDetails();
  }, [slug]);

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [mobile, setMobile] = useState("");
  const [final, setfinal] = useState('');
  const [otp, setotp] = useState('');
  const [show, setshow] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [review, setreview] = useState("Submit Review");
  
  const submitForm = () => {
    
    let state = {
        name : name,
        message : message,
        mobile : mobile,
    }
    if(name != '' && message != '' && mobile != '')
    {
      if (mobile === "" || mobile.length < 10) return;
        var code ='+91';
        var  phoneinput = code.concat(mobile);
        localStorage.setItem('formData', JSON.stringify(state));
        let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        auth.signInWithPhoneNumber(phoneinput, verify).then((result) => {
            setfinal(result);
            MySwal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'OTP Send to Your Mobile No.',
              showConfirmButton: false,
              timer: 3000
            })
            setshow(true);
        })
            .catch((err) => {
                MySwal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: err,
                  showConfirmButton: false,
                  timer: 3000
                })
            });
    }
    else
    {
      MySwal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Please Fill All Details',
        showConfirmButton: false,
        timer: 3000
      })
    }
  };

  // Validate OTP
  const ValidateOtp = () => {
    if (otp === null || final === null)
        return;
    final.confirm(otp).then((result) => {
      setreview("Submitting...");
      setdisabled(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("mobile", mobile);
      formData.append("message", message);
      formData.append("blog_id", page.blog_details.id);
      axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/submit_blog_review`, formData)
      .then((res) => {
        if (res.status === 200) 
        {
          setName("");
          setMessage("");
          setMobile("");
          MySwal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Message Submitted Successfully.',
            showConfirmButton: false,
            timer: 3000
          })
          setreview("Submit Review");
          setdisabled(false);
          setshow(false);
        } 
        else 
        {
          MySwal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Message Submitted Failed. Please Try Again.',
            showConfirmButton: false,
            timer: 3000,
            width : '20rem',
          })
        }
      })
    }).catch((err) => {
        MySwal.fire({
        position: 'top-end',
        icon: 'error',
        title: err,
        showConfirmButton: false,
        timer: 3000
      })
    })
  }
 

  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
      <LoadingBar
        color='#f11946'
        progress={progress}
        height={3}
        onLoaderFinished={() => setProgress(0)}
      />
        <div className="breadcrumbs-area bread-bg-1 bg-opacity-black-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumbs">
                  <h2 className="breadcrumbs-title">
                    {page.blog_details.title}
                  </h2>
                  <ul className="breadcrumbs-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>{`${page.blog_details.title.substring(0, 20)}...`}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="page-content" className="page-wrapper">
          <div className="blog-area pt-115 pb-120">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12">
                  <div className="blog-details-area">
                    <div className="blog-details-image">
                      <img src={url+page.blog_details.image} alt={page.blog_details.title} />
                    </div>
                    <div className="blog-details-title-time">
                      <h5>{page.blog_details.title}</h5>
                      <p><Moment>{page.blog_details.date}</Moment></p>
                    </div>
                    <div className="blog-details-desctiption mb-60">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: page.blog_details.content,
                          }}
                        />
                    </div>
                    <div className="blog-details-share-tags clearfix mb-75">
                      <div className="blog-details-tags f-left d-none">
                        <ul>
                          <li>Tags :</li>

                          <li>
                            <Link to="#">Apartment,</Link>
                          </li>
                          <li>
                            <Link to="#">Building,</Link>
                          </li>
                          <li>
                            <a to="#">Real Estate,</a>
                          </li>
                          <li>
                            <a to="#">Commercial</a>
                          </li>
                        </ul>
                      </div>
                      <div className="blog-details-share f-right d-none">
                        <ul className="social-media">
                          <li>Share:</li>
                          <li>
                            <a to="#">
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a to="#">
                              <i
                                className="fa fa-twitter"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a to="#">
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a to="#">
                              <i
                                className="fa fa-google-plus"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="blog-details-author-post clearfix line-bottom pb-30 mb-115">
                      <div className="blog-details-author-image d-none">
                        <a to="agent-details.html">
                          <img src="images/avatar/1.jpg" alt="" />
                        </a>
                      </div>
                      <div className="blog-details-author-desc d-none">
                        <div className="blog-details-author-name">
                          <h6>
                            <a to="agent-details.html">Robin Patrick</a>
                          </h6>
                          <p className="">Author</p>
                        </div>
                        <p>
                          There are some business lorem ipsum dolor sit amet,
                          consectetur adipiscing elit, sed do eiusmod tempor inc
                          ididunt ut labore et dolore magna aliqua. Ut enim ad
                          minim veniam,{" "}
                        </p>
                      </div>
                    </div>
                    
                    <div className="blog-details-reply leave-review">
                      <h5>Leave a Coment</h5>
                      <form style={{ display: !show ? "block" : "none" }}>
                        <div className="row">
                          <div className="col-lg-6">
                            <input
                              type="text"
                              name="name"
                              placeholder="Enter Your Name"
                              value={name}
                              onChange={(e) =>
                                setName(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-lg-6">
                            <input
                              type="text"
                              name="mobile"
                              placeholder="Your Mobile No"
                              value={mobile}
                              onChange={(e) =>
                                setMobile(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <textarea 
                          placeholder="Enter Comment.."
                          value={message}
                          onChange={(e) =>
                            setMessage(e.target.value)
                          }>
                          </textarea>
                          <div id="recaptcha-container"></div>
                        <button onClick={submitForm} type="button" className="submit-btn-1">
                          SUBMIT COMMENT
                        </button>
                      </form>
                      <form style={{ display: show ? "block" : "none" }}>
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter Otp"
                          value={otp}
                          onChange={(e) =>
                            setotp(e.target.value)
                          }
                        />
                        <button disabled={disabled} onClick={ValidateOtp} type="button" className="submit-btn-1">
                          {review}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-12">
                  <aside className="widget widget-categories mb-50">
                    <h5>Categories</h5>
                    <ul className="widget-categories-list">
                        {page.all_category.map((category, i) => (
                            <li  key={category.category_id+i}>
                                <Link to={'/blog-category/'+category.category_slug}>
                                {category.category_name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                  </aside>
                  <aside className="widget widget-recent-post mb-50">
                    <h5>Recent Post</h5>
                    <div className="row">
                        {page.top_4_blog.map((top_blog, i) => (
                            <div className="col-lg-12 col-md-6 col-12" key={top_blog.id+i}>
                                <article className="recent-post-item">
                                    <div className="recent-post-image">
                                        <Link to={'/blog-details/'+top_blog.title_slug}>
                                        <img
                                            src={url+top_blog.image}
                                            alt={top_blog.title}
                                        />
                                        </Link>
                                    </div>
                                    <div className="recent-post-info">
                                        <div className="recent-post-title-time">
                                        <h5>
                                            <Link to={'/blog-details/'+top_blog.title_slug}>
                                            {top_blog.title}
                                            </Link>
                                        </h5>
                                        <p><Moment>{top_blog.date}</Moment></p>
                                        </div>
                                        <p>{`${top_blog.short_description.substring(0, 20)}...`}</p>
                                    </div>
                                </article>
                            </div>
                        ))}
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
          <div className="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>SUBSCRIBE</h3>
                    <h2 className="h1">NEWSLETTER</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="subscribe">
                    <form action="#">
                      <input
                        type="text"
                        name="subscribe"
                        placeholder="Enter yur email here..."
                      />
                      <button type="submit" value="send">
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
