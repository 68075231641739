import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import { Link } from "react-router-dom";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";

export default function Agent() {
  const url = process.env.REACT_APP_BASE_URL;

  // Agent API
  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [all_agent, setItemsHome] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemshome = async () => {
      setProgress(progress + 40);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/get_all_agent`)
        .then(function (resultHome) {
          setProgress(80);
          setItemsHome(resultHome.data);
          setLoading(false);
          setProgress(100);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);
  // Agent API

  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
        <LoadingBar
            color='#f11946'
            progress={progress}
            height={3}
            onLoaderFinished={() => setProgress(0)}
        />
        <div className="breadcrumbs-area bread-bg-1 bg-opacity-black-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumbs">
                  <h2 className="breadcrumbs-title">Agent</h2>
                  <ul className="breadcrumbs-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Agent</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="page-content" className="page-wrapper">
          <div className="our-agents-area  pt-115 pb-60">
            <div className="container">
              <div className="row">
                {all_agent.map((agent, i) => (
                    <div className="col-lg-3 col-md-4 col-12" key={'vvvv'+i}>
                        <div className="single-agent">
                            <div className="agent-image">
                              <img src={url+agent.image} alt={agent.name} />
                            </div>
                            <div className="agent-info">
                              <div className="agent-name">
                                <h5>
                                  <Link to={'/excutive-details/'+agent.slug}>
                                  {agent.name}
                                  </Link>
                                </h5>
                                <p>{agent.position}</p>
                              </div>
                            </div>
                            <div className="agent-info-hover">
                              <div className="agent-name">
                                <h5>
                                  <Link to={'/excutive-details/'+agent.slug}>
                                    {agent.name}
                                  </Link>
                                </h5>
                                <p>{agent.position}</p>
                              </div>
                              <ul className="agent-address">
                                <li>
                                  <img
                                    src="/front/images/icons/phone-2.png"
                                    alt=""
                                  />{agent.mobile}
                                </li>
                                <li>
                                  <img
                                    src="/front/images/icons/mail-close.png"
                                    alt=""
                                  />{agent.email}
                                </li>
                              </ul>
                              <ul className="social-media">
                                <li>
                                  <a href={agent.fb_url}>
                                    <i
                                      className="fa fa-facebook"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.tw_url}>
                                    <i
                                      className="fa fa-twitter"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.linkdin_url}>
                                    <i
                                      className="fa fa-linkedin"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.telegram_url}>
                                    <i
                                      className="fa fa-telegram"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="col-12 d-none">
                  <div className="pagination-area mb-60">
                    <ul className="pagination-list text-center">
                      <li>
                        <Link to="#">
                          <i
                            className="fa fa-angle-left"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">1</Link>
                      </li>
                      <li>
                        <Link to="#">2</Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>SUBSCRIBE</h3>
                    <h2 className="h1">NEWSLETTER</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="subscribe">
                    <form action="#">
                      <input
                        type="text"
                        name="subscribe"
                        placeholder="Enter yur email here..."
                      />
                      <button type="submit" value="send">
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
