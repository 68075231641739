import "./custom.css";
import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import $ from "jquery";

export default function LandingPage() {
  var slider = {
    animateOut: "slideOutDown",
    animateIn: "heartBeat",
    items: 1,
    loop: false,
    nav: true,
    dots: true,
    
    
  };
  var overview = {
    animateOut: "fadeOut",
    animateIn: "heartBeat",
    items: 1,
    autoplay: true,
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
  };
  var threerd_cousole = {
    animateOut: "slideOutDown",
    animateIn: "heartBeat",
    items: 1,
    autoplay: true,
    loop: true,
    nav: false,
    dots: false,
    margin: 20,
    autoplayTimeout: 2000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  var fourth_cousole = {
    animateOut: "slideOutDown",
    animateIn: "heartBeat",
    items: 1,
    autoplay: true,
    loop: true,
    nav: false,
    dots: false,
    margin: 20,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  var fiveth_cousole = {
    animateOut: "slideOutDown",
    animateIn: "heartBeat",
    items: 1,
    autoplay: true,
    loop: true,
    nav: false,
    dots: false,
    margin: 20,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  function open_pdf_model() {
    $("#pdf_form_model").modal("show");
  }
  function submitDetailsForm() {
    $("#pdf_form_model").modal("hide");
    $("#formId").submit();
  }
  function open_form_model() {
    $("#form_model").modal("show");
  }

  const { category } = useParams();
  const { slug } = useParams();
  // Landing Page API
  const [isLoading, setLoading] = useState(true);
  const [page, setItemsHome] = useState([]);
  useEffect(() => {
    const getItemshome = async () => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/get_project_by_details/${category}/${slug}`
        )
        .then(function (resultHome) {
          setItemsHome(resultHome.data);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);

  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [projectId, setProjectid] = useState("");
  const submitForm = () => {
    //setIsLoadedSubmit(true);
    const formData = new FormData();
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("name", name);
    formData.append("project_id", page.project.id);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/submit_inquery_data`, formData)
      .then((res) => {
        if (res.status === 200) {
          setMobile("");
          setEmail("");
          setName("");
          window.location.assign(`/thankyou/${page.project.id}`);
        } else {
          
        }
      })
      // console.log(mobile);
  };

  var url = process.env.REACT_APP_BASE_URL;

  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
        <Helmet>
          <meta charset="utf-8" />
          <title>{page.title}</title>
          <link rel="icon" type="image/png" href={url + page.project.fevicon} sizes="16x16" />
          {page.project.header_code}
        </Helmet>

        <div
          className="modal fade show"
          id="pdf_form_model"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-xl">
              <div className="modal-header model-header-custom pt-4">
                <button
                  type="button "
                  style={{ fontSize: "12px" }}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body model-body-custom "
                style={{ paddingTop: "40px" }}
              >
                <h6 className="text-center pb-2 title">
                  PLEASE ENTER YOUR DETAILS BELOW
                </h6>
                <form>
                <div className="m-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Full Name"
                      name="name"
                      value={name}
                      onChange={(e) =>
                        setName(e.target.value)
                      }
                    />
                  </div>
                  
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Email ID"
                      name="email"
                      value={email}
                      onChange={(e) =>
                        setEmail(e.target.value)
                      }
                    />
                  </div>
                  
                  <div className="form-group pb-2">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Your Mobile No"
                      name="mobile"
                      value={mobile}
                      onChange={(e) =>
                        setMobile(e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group ">
                    <input type="hidden" name="pdf_url" id="pdf_url" />
                  </div>
                  <div className="form-group ">
                    <input type="hidden" name="1_url" value="" />
                  </div>
                  <div className="form-group ">
                    <input type="hidden" name="2_url" value="" />
                  </div>
                  <div className="form-group ">
                    <input type="hidden" name="project_id" onChange={(e) =>
                        setProjectid(e.target.value)
                      } value={page.project.id} />
                  </div>
                  {/* <div className="form-group">
                    <label className="container-checkbox">
                      Opt-in for receiving important information and updates on
                      WhatsApp
                      <input
                        type="checkbox"
                        value="1"
                        checked="defaultChecked"
                        name="whatsapp"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div> */}
                  <div
                    className="form-group text-center"
                    style={{ marginBottom: "0" }}
                  >
                    <button
                      id="stm_btn"
                      className="submit-button"
                      type="button"
                      style={{ verticalAlign: "middle" }}
                      onClick={submitForm}
                    >
                      <span>Submit </span>
                    </button>
                  </div>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>



        <div
          className="modal fade show"
          id="form_model"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-xl">
              <div className="modal-header model-header-custom pt-4">
              
              </div>
              <div className="modal-body model-body-custom" style={{ paddingTop: "40px" }}>
                <h6 className="text-center pb-2 title">
                  PLEASE ENTER YOUR DETAILS BELOW
                </h6>
                <div className="m-4 mb-1">
                <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Full Name"
                      name="name"
                      value={name}
                      onChange={(e) =>
                        setName(e.target.value)
                      }
                    />
                  </div>
                  
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Email ID"
                      name="email"
                      value={email}
                      onChange={(e) =>
                        setEmail(e.target.value)
                      }
                    />
                  </div>
                  
                  <div className="form-group pb-2">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Your Mobile No"
                      name="mobile"
                      value={mobile}
                      onChange={(e) =>
                        setMobile(e.target.value)
                      }
                    />
                  </div>
                  
                 
                  <div className="form-group text-center" style={{ marginBottom: "0" }}>
                  <button
                      className="submit-button"
                      type="button"
                      style={{ verticalAlign: "middle" }}
                      onClick={submitForm}
                    >
                      <span>Submit </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header>
          <nav
            id="navbar_top"
            className="navbar navbar-expand-lg navbar-dark"
            style={{ backgroundColor: page.project.header_color }}
          >
            <div className="container">
              <a className="navbar-brand" href="#">
                <img
                  src={url + page.project.logo}
                  alt="Eternia Kalpataru Parkcity"
                />
              </a>
              <div className="d-none d-lg-block">
                <h1 className="wiggle">
                  <button
                    className="button button"
                    onClick={open_form_model}
                  >
                    <b>Book Your Free Site Visit Now !</b>
                  </button>
                </h1>
              </div>
              <div className="d-lg-none">
                <h1 className="wiggle">
                  <button
                    className="button button"
                    onClick={open_form_model}
                  >
                    <b>inquiry Now</b>
                  </button>
                </h1>
              </div>
            </div>
          </nav>
        </header>
        {page.section.slider == 1 ? (
          <section>
            <OwlCarousel className="hero-slider owl-theme" {...slider}>
              {page.all_slider.map((slider_details, i) => (
                <div key={slider_details.id+i}>
                  {slider_details.heading ? 
                    <div
                      className="single-hs-item"
                      style={{
                        backgroundImage: `url(${url + slider_details.image})`,
                      }}
                    >
                      <div className="d-table">
                        <div className="d-tablecell">
                          <div className="hero-text">
                            <h1 style={{ color: page.project.slider_title_color }}>
                              {slider_details.heading}
                            </h1>
                            <p
                              style={{ color: page.project.slider_sub_title_color }}
                            >
                              {slider_details.sub_heading}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  :
                  <div
                      className="single-hs-item"
                     
                    >
                      <img src={url + slider_details.image}/>
                      <div className="d-table">
                        <div className="d-tablecell">
                          <div className="hero-text">
                            <h1 style={{ color: page.project.slider_title_color }}>
                              {slider_details.heading}
                            </h1>
                            <p
                              style={{ color: page.project.slider_sub_title_color }}
                            >
                              {slider_details.sub_heading}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              ))}
            </OwlCarousel>
          </section>
        ) : (
          <p></p>
        )}
        {page.section.card == 1 ? (
          <section className="">
            <div className="container">
              <div className="row d-flex align-items-center ">
              {page.all_card.map((card, i) => (
                <div className="col-sm-4 col-12 pt-3" key={card.id+i}>
                  <div className="card">
                      <div className="card-body text-center"  >
                        <h5 className="card-title">
                          <img
                            src={url + card.image}
                            width="50px"
                            alt="Eternia Kalpataru Parkcity"
                          />
                        </h5>
                        <p className="card-text">{card.des}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : (
          <p></p>
        )}
        {page.section.overview_about == 1 ? (
          <section className="mt-4">
            <div className="container">
              <div className="row pb-3">
                <div className="col-sm-12">
                  <h4 style={{ color: page.project.heading_color }}>
                    01 OVERVIEW
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <OwlCarousel className="owl-theme overview" {...overview}>
                    {page.about_us_slider.map((us_slider, i) => (
                      <div className="item" key={us_slider.id+i}>
                        <img
                          className="img-fluid"
                          src={url + us_slider.image}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
                <div className="col-md-7 offset-md-1 align-self-center content-1">
                  <h2 className="text-center pb-4">{page.content.heading}</h2>
                  <p>{page.content.content}</p>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <p></p>
        )}

        <section className="mt-3 text-center">
          <div className="container">
            <div className="col-sm-12">
              <h1 className="wiggle_1">
                <button
                  className="button_1"
                  onClick={open_form_model}
                >
                  <b>Book Your Free Site Visit Now !</b>
                </button>
              </h1>
            </div>
          </div>
        </section>
        {page.section.overview_slider == 1 ? (
          <section className="mt-4">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <OwlCarousel
                    className="owl-theme 3rd_cousole"
                    {...threerd_cousole}
                  >
                    {page.all_over_view_slider.map((over_slider, i) => (
                      <div className="item" key={over_slider.id+i}>
                        <img
                          className="img-fluid"
                          src={url + over_slider.image}
                          alt={url + over_slider.title}
                        />
                        <h6 className="text-center pt-4">{over_slider.title}</h6>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <p></p>
        )}
        {page.section.amenities == 1 ? (
          <section className="mt-5">
            <div className="container">
              <div className="row mb-4">
                <div className="col-sm-12">
                  <h4 style={{ color: page.project.heading_color }}>
                    02 INDOOR AMENITIES
                  </h4>
                </div>
              </div>
              <div className="row d-flex align-items-center">
                <div className="col-sm-12 ">
                  
                    <OwlCarousel
                      className="owl-theme 4th_cousole"
                      {...fourth_cousole}
                    >
                      {page.indoor_amenities.map((indoor, i) => (
                        <div className="item d-flex align-items-center" key={indoor.id+i}>
                          <div className="grey-icon-bg ">
                            <img
                              className="img-fluid"
                              src={url + indoor.image}
                              alt={indoor.title}
                            />
                          </div>
                          <div className="">
                            <h6 className="text-center">
                              {indoor.content}
                            </h6>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  
                </div>
              </div>
              <div className="row mb-4 mt-5">
                <div className="col-sm-12">
                  <h4 style={{ color: page.project.heading_color }}>
                    03 OUTDOOR AMENITIES
                  </h4>
                </div>
              </div>
              <div className="row d-flex align-items-center">
                <div className="col-sm-12">
                  <OwlCarousel
                    className="owl-theme 4th_cousole"
                    {...fourth_cousole}
                  >
                    {page.all_amenities.map((outdoor_amenities, i) => (
                      <div className="item d-flex align-items-center" key={outdoor_amenities.id+i}>
                        <div className="grey-icon-bg ">
                          <img
                            className="img-fluid"
                            src={url + outdoor_amenities.image}
                            alt={outdoor_amenities.title}
                          />
                        </div>
                        <div className="">
                          <h6 className="text-center">
                            {outdoor_amenities.content}
                          </h6>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>

              
            </div>
          </section>
        ) : (
          <p></p>
        )}
        <section className="mt-5 text-center">
          <div className="container">
            <div className="col-sm-12">
              <h1 className="wiggle_1">
                <button
                  className="button_1"
                  onClick={open_form_model}
                >
                  <b>Book Your Free Site Visit Now !</b>
                </button>
              </h1>
            </div>
          </div>
        </section>
        {page.section.resources == 1 ? (
          <section className="mt-5">
            <div className="container">
              <div className="row mb-4">
                <div className="col-sm-12">
                  <h4 style={{ color: page.project.heading_color }}>
                    04 RESOURCES
                  </h4>
                </div>
              </div>
              {page.all_resource.map((resource, i) => (
                <div className="row d-flex align-items-center" key={resource.id+i}>
                  <div className="col-sm-6 col-8">
                    <div className="d-flex align-items-center">
                      <div className="grey-icon-bg ">
                        <img
                          className="img-fluid"
                          src={url + resource.image}
                          alt={resource.title}
                        />
                      </div>
                      <div className="">
                        <h6 className="text-center">{resource.content}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-4">
                    <button
                      href="#"
                      onClick={open_pdf_model}
                      data-bs-toggle="modal"
                      data-bs-target="#pdf_form_model"
                      className="icon-button"
                    >
                      <div className="round-button">
                        <div className="circle"></div>
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </section>
        ) : (
          <p></p>
        )}
        {page.section.location == 1 ? (
          <section className="mt-5">
            <div className="container">
              <div className="row mb-4">
                <div className="col-sm-12">
                  <h4 style={{ color: page.project.heading_color }}>
                    05 LOCATE
                  </h4>
                </div>
              </div>
              <div className="row">
                {page.section.address == 1 ? 
                  <div className="col-sm-6 address align-self-center">
                    <span className="cla ">
                      <h4>{page.location.name}</h4>
                      <h6>
                        Mobile : <a href="">{page.location.mobile}</a>
                      </h6>
                      <h6>
                        Email ID : <a href="mailto:">{page.location.email}</a>
                      </h6>
                      <h6>{page.location.time}</h6>
                      <h6>{page.location.address}</h6>
                    </span>
                  </div>
                :
                <p></p>
                }
                {page.section.map == 1 ? 
                  <div className="col-sm-6">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page.location.map,
                      }}
                    />
                  </div>
                :
                <p></p>
                }
              </div>
            </div>
          </section>
        ) : (
          <p></p>
        )}
        {page.section.rera_details == 1 ? (
          <section className="mt-3">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="accordion" id="myAccordion">
                    <div className="accordion-item">
                      <h1 className="accordion-header" id="headingOne">
                        <button
                          style={{ fontSize: "25px" }}
                          type="button"
                          className="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          RERA DETAILS
                        </button>
                      </h1>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#myAccordion"
                      >
                        <div className="card-body">
                          <p>
                            HTML stands for HyperText Markup Language. HTML is
                            the standard markup language for describing the
                            structure of web pages.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <p></p>
        )}
        <section className="mt-3 text-center">
          <div className="container">
            <div className="col-sm-12">
              <h1 className="wiggle_1">
                <button
                  className="button_1"
                  onClick={open_form_model}
                >
                  <b>Book Your Free Site Visit Now !</b>
                </button>
              </h1>
            </div>
          </div>
        </section>
        <section className="mt-3 text-center d-none">
          <div className="container">
            <div className="col-sm-12">
              <h1 className="wiggle_1">
                <button
                  className="button_1"
                  onClick={open_form_model}
                >
                  <b>Book Your Free Site Visit Now !</b>
                </button>
              </h1>
            </div>
          </div>
        </section>
        <section className="mt-5 bg-dark text-center footer">
          <p className="text-white pt-3">
            This site is not a part of the Facebook™ website or Facebook™ Inc.
            Additionally, This site is NOT endorsed by Facebook™ in any way.
            FACEBOOK is a trademark of FACEBOOK, Inc.
          </p>
          <p className="text-white pb-3">
            Please be advised that revenues and results shown are extraordinary
            and are not intended to serve as guarantees. In fact, as stipulated
            by law, we can not and do not make any guarantees about your ability
            to get results or earn any money with our ideas, information, tools
            or strategies. We don’t know you and, besides, your results in life
            are up to you. Agreed? We just want to help you by giving great
            content, direction and strategies that worked well for us and our
            students and that we believe can move you forward. All of our terms,
            privacy policies and disclaimers for this program and website can be
            accessed via the link above. We feel transparency is important and
            we hold ourselves (and you) to a high standard of integrity. Thanks
            for stopping by. We hope this training and content brings you a lot
            of value.
          </p>
        </section>
      </>
    );
  }
}
