import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import LoadingBar from 'react-top-loading-bar'
Moment.globalFormat = 'D MMM YYYY hh:mm A';


export default function Blog() {
   const url = process.env.REACT_APP_BASE_URL;
  // Blog API
  const [progress, setProgress] = useState(0)
  const [isLoading, setLoading] = useState(true);
  const [blog, setItemsHome] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemshome = async () => {
      setProgress(progress + 40);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/get_all_blog`)
        .then(function (resultHome) {
          setProgress(80)
          setItemsHome(resultHome.data);
          setLoading(false);
          setProgress(100);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);
  // Blog API

  const [query, setQuery] = useState("")
 
  if (isLoading) {
    return <PreLoader />;
  } else {
    var post_data = blog.all_blog
    .filter(post => {
        if (query === '') {
          return post;
        } else if (post.title.toLowerCase().includes(query.toLowerCase())) {
          return post;
        }
    })
    return (
      <>
      <LoadingBar
        color='#f11946'
        progress={progress}
        height={3}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="breadcrumbs-area bread-bg-1 bg-opacity-black-70">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumbs">
                            <h2 className="breadcrumbs-title">Blog</h2>
                            <ul className="breadcrumbs-list">
                                <li><Link to="/">Home</Link></li>
                                <li>Blog</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="page-content" className="page-wrapper">
          <div className="blog-area left-sidebar pt-115 pb-60">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 order-lg-2">
                  <div className="row">
                    {post_data.length > 0 ?
                      post_data.map((blog_details, i) => (
                        <div className="col-md-6 col-12" key={blog_details.id+i}>
                            <article className="blog-item bg-gray">
                                <div className="blog-image">
                                    <Link to={'/blog-details/'+blog_details.title_slug}>
                                        <img src={url+blog_details.image} alt={blog_details.title} />
                                    </Link>
                                </div>
                                <div className="blog-info">
                                    <div className="post-title-time">
                                        <h5>
                                        <Link to={'/blog-details/'+blog_details.title_slug}>
                                        {`${blog_details.title.substring(0, 30)}...`}</Link>
                                        </h5>
                                        <p><Moment>{blog_details.date}</Moment></p>
                                    </div>
                                    <p>{`${blog_details.short_description.substring(0, 100)}...`}</p>
                                    <Link className="read-more" to={'/blog-details/'+blog_details.title_slug}>
                                        Read more
                                    </Link>
                                </div>
                            </article>
                        </div>
                      ))
                    :
                      <div div className="col-md-12 col-12 text-center">
                          <h2>No Data Found</h2>
                      </div>
                    }
                    <div className="col-12 d-none">
                      <div className="pagination-area mb-60">
                        <ul className="pagination-list text-center">
                          <li>
                            <Link to="#">
                              <i
                                className="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">1</Link>
                          </li>
                          <li>
                            <Link to="#">2</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-lg-1">
                  <aside className="widget widget-search mb-30">
                    <form action="#">
                      <input type="text" name="search" placeholder="Search Blog..." onChange={event => setQuery(event.target.value)}
                      />
                    </form>
                  </aside>
                  <aside className="widget widget-categories mb-50">
                    <h5>Categories</h5>
                    <ul className="widget-categories-list">
                        {blog.all_category.map((category, i) => (
                            <li key={category.category_id+i}>
                                <Link to={'/blog-category/'+category.category_slug}>
                                {category.category_name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                  </aside>
                  <aside className="widget widget-recent-post mb-50">
                    <h5>Recent Post</h5>
                    <div className="row">
                        {blog.top_4_blog.map((top_blog, i) => (
                            <div className="col-lg-12 col-md-6 col-12" key={top_blog.id+i}>
                                <article className="recent-post-item">
                                    <div className="recent-post-image">
                                        <Link to={'/blog-details/'+top_blog.title_slug}>
                                        <img
                                            src={url+top_blog.image}
                                            alt={top_blog.title}
                                        />
                                        </Link>
                                    </div>
                                    <div className="recent-post-info">
                                        <div className="recent-post-title-time">
                                        <h5>
                                            <Link to={'/blog-details/'+top_blog.title_slug}>
                                            {top_blog.title}
                                            </Link>
                                        </h5>
                                        <p><Moment>{top_blog.date}</Moment></p>
                                        </div>
                                        <p>{`${top_blog.short_description.substring(0, 20)}...`}</p>
                                    </div>
                                </article>
                            </div>
                        ))}
                    </div>
                  </aside>
                  <aside className="widget widget-archive mb-50 d-none">
                    <h5>Archive</h5>
                    <ul className="widget-archive-list">
                      <li>
                        <Link to="#">
                          August <span>2017</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          June <span>2017</span>{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          May <span>2017</span>{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          April <span>2017</span>{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          March <span>2017</span>{" "}
                        </Link>
                      </li>
                    </ul>
                  </aside>
                  <aside className="widget widget-latest-tweets mb-50 d-none">
                    <h5>Latest Tweets</h5>
                    <div className="tweets-item">
                      <p>
                        <Link to="#">@Lorem ipsum</Link> dolor sit amet, costetur
                        adipiscing elit, sed do eiusmod tempor{" "}
                      </p>
                      <p>Ronchi / 3 hour ago</p>
                    </div>
                    <div className="tweets-item">
                      <p>
                        <Link to="#">@Lorem ipsum</Link> dolor sit amet, costetur
                        adipiscing elit, sed do eiusmod tempor{" "}
                      </p>
                      <p>Ronchi / 3 hour ago</p>
                    </div>
                    <div className="tweets-item">
                      <p>
                        <Link to="#">@Lorem ipsum</Link> dolor sit amet, costetur
                        adipiscing elit, sed do eiusmod tempor{" "}
                      </p>
                      <p>Ronchi / 3 hour ago</p>
                    </div>
                  </aside>
                  <aside className="widget widget-add mb-0 d-none">
                    <div className="widget-add-item">
                      <div className="widget-add-image">
                        <Link to="#">
                          <img src="/front/images/others/add.jpg" alt="" />
                        </Link>
                        <div className="widget-add-info">
                          <h5>
                            <Link to="#">
                              <span>25% off</span> <br /> Build Your <br />{" "}
                              Dream with Us
                            </Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
          <div className="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>SUBSCRIBE</h3>
                    <h2 className="h1">NEWSLETTER</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="subscribe">
                    <form action="#">
                      <input
                        type="text"
                        name="subscribe"
                        placeholder="Enter yur email here..."
                      />
                      <button type="submit" value="send">
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
