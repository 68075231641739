import React, { useState, useEffect } from "react";
import "./thankyouPageCss.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import PreLoader from "./PreLoader";

export default function Thankyou() {
  const { slug } = useParams();
  var url = process.env.REACT_APP_BASE_URL;

  const [isLoading, setLoading] = useState(true);
  const [page, setItemsHome] = useState([]);
  useEffect(() => {
    const getItemshome = async () => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/get_thankyou_page_by_project_id/${slug}`
        )
        .then(function (resultHome) {
          setItemsHome(resultHome.data);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);

  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
        <Helmet>
          <meta charset="utf-8" />
          <title>{page[0].name}</title>
          <link
            rel="icon"
            type="image/png"
            href={url + page[0].fevicon}
            sizes="16x16"
          />
          {page[0].header_code}
        </Helmet>
        <header>
          <nav
            id="navbar_top"
            className="navbar navbar-expand-lg navbar-dark"
            style={{ backgroundColor: page[0].header_bg_color }}
          >
            <div className="container">
              <a className="navbar-brand" href="#">
                <img src={url + page[0].logo} alt={url + page[0].name} />
              </a>
            </div>
          </nav>
        </header>
        <section>
          <div className="pt-3 pb-3">
            <div className="header-text1 mb-0">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="text-center">
                      <h1
                        className=" display-5 font-weight-semibold"
                        style={{ color: page[0].heading_color }}
                      >
                        {page[0].heading}
                      </h1>
                      <p
                        style={{ color: page[0].sub_heading_color }}
                        className=" lead"
                      >
                        {page[0].sub_heading}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb bg-white pb-0">
          <div className="container d-flex justify-content-center">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="text-center">
                  
                    {page[0].video_or_image == 2 ?
                      <div className="border iframe-border" style={{backgroundColor: page[0].iframe_color}}>
                        <iframe width="100%" height="360" src={page[0].video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                    :
                      <div className="border iframe-border-image" style={{backgroundColor: page[0].iframe_color }}>
                        <img className="iframe_image" src={url+page[0].image} width="100%"/>
                      </div>
                    }
                    
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb bg-white pb-5 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h2>
                  <b style={{ color: page[0].heading_color }}>
                    {page[0].content}
                  </b>
                </h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page[0].content_des,
                    }}
                  />
              </div>
            </div>
          </div>
        </section>
        <section className="mt-5">
          <div className="container">
            <div className="section-title">
              <div className="text-center ">
                <h2 className="" style={{ color: page[0].heading_color }}>
                  {page[0].b_heading}
                </h2>
                <h5 className="" style={{ color: page[0].sub_heading_color }}>
                  {page[0].b_sub_heading}
                </h5>
              </div>
            </div>
          </div>
        </section>
        <section className="sptb bg-white">
          <div className="container">
            <div className="row text-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <div className="text-center">
                  <div className="d-inline-flex" style={{ padding: "5px" }}>
                    <a href={page[0].b_sub_heading}>
                      <button className="btn btn-sm facebook">
                        <i className="fab fa-facebook"></i>&nbsp; Facebook Group
                      </button>
                    </a>
                  </div>
                  <div className="d-inline-flex " style={{ padding: "5px" }}>
                    <a href={page[0].wh}>
                      <button className="btn btn-sm whatsapp">
                        <i className="fa fa-whatsapp"></i>&nbsp; Whatsapp Group
                      </button>
                    </a>
                  </div>
                  <div className="d-inline-flex" style={{ padding: "5px" }}>
                    <a href={page[0].you}>
                      <button className="btn btn-sm youtube">
                        <i className="fa fa-youtube-play"></i>&nbsp; Youtube
                        Channel
                      </button>
                    </a>
                  </div>
                  <div className="d-inline-flex" style={{ padding: "5px" }}>
                    <a href={page[0].insta}>
                      <button
                        className="btn btn-sm instagram"
                        style={{ color: "#fff" }}
                      >
                        <i className="fa fa-instagram"></i>&nbsp; Instagram
                      </button>
                    </a>
                  </div>
                  <div className="d-inline-flex" style={{ padding: "5px" }}>
                    <a href={page[0].tele}>
                      <button className="btn btn-sm telegram">
                        <i className="fa fa-telegram"></i>&nbsp; Telegram
                        Channel
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
