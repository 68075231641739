import firebase from 'firebase';



const firebaseConfig = {
  apiKey: "AIzaSyA2FOU8Bk3CKhYGSfpj1qwee-MJA5ci-5Y",
  authDomain: "real-estate-1afcc.firebaseapp.com",
  projectId: "real-estate-1afcc",
  storageBucket: "real-estate-1afcc.appspot.com",
  messagingSenderId: "741761374194",
  appId: "1:741761374194:web:6be5eae9cc93a476677d6b"
  };
  
firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export {auth , firebase};