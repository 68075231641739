import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import axios from "axios";

export default function Services() {
  // Base Url
  const url = process.env.REACT_APP_BASE_URL;
  // Base Url
  var settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1169,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      }, // Medium Device
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      }, // Tablet
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      }, // Large Mobile
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Small Mobile
    ],
  };
  var testimonial_carousel = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
 

  // Service API
  const [progress, setProgress] = useState(0)
  const [isLoading, setLoading] = useState(true);
  const [service, setItemsService] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemsService = async () => {
      setProgress(50);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/get_all_service`)
        .then(function (resultService) {
          setProgress(80);
          setItemsService(resultService.data);
          setLoading(false);
          setProgress(100);
          
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemsService();
  }, []);
  // Service API

  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
      <LoadingBar
        color='#f11946'
        progress={progress}
        height={3}
        onLoaderFinished={() => setProgress(0)}
      />
        <div
          className="breadcrumbs-area bread-bg-1"
          
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumbs">
                  <h2 className="breadcrumbs-title">Service</h2>
                  <ul className="breadcrumbs-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Service</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="page-content" className="page-wrapper">
          <div className="about-sheltek-area ptb-55">
           
          </div>
          <div className="services-area pb-60">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title-2 text-center">
                    <h2>{service.section_title.our_service_title}</h2>
                    <p>{service.section_title.our_service_sub_title}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                {service.all_service.map((sevice_d, i) => (
                  <div className="col col-sm-3" key={sevice_d.service_id+i}>
                    <div className="service-item">
                      <div className="service-item-image">
                        <Link to={"/services/" + sevice_d.service_slug}>
                          <img
                            src={url + sevice_d.service_image}
                            alt={sevice_d.service_title}
                          />
                        </Link>
                      </div>
                      <div className="service-item-info">
                        <h5>
                          <Link to={"/services/" + sevice_d.service_slug}>
                            {sevice_d.service_title}
                          </Link>
                        </h5>
                        <p>
                          {`${sevice_d.service_short_description.substring(
                            0,
                            100
                          )}...`}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="booking-area bg-1 call-to-bg plr-140 pt-75" style={{ backgroundImage: `url(${url + service.booking_section.bg_image})` }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>{service.booking_section.one_st_title}</h3>
                    <h2 className="h1">{service.booking_section.one_st_sub_title}</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="booking-conternt clearfix">
                    <div className="book-house text-white">
                      <h2>{service.booking_section.two_nd_title}</h2>
                      <h2 className="h5">{service.booking_section.two_nd_sub_title}</h2>
                    </div>
                    <div className="booking-imgae">
                      <img src={url + service.booking_section.image} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="testimonial-area pb-115 pt-90">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="testimonial">
                    <div className="row">
                      <div className="col-lg-8 col-md-9">
                        <div className="section-title mb-30">
                          <h3>{service.all_testimonial.th_heading}</h3>
                          <h2 className="h1">{service.all_testimonial.th_sub_heading}</h2>
                        </div>
                        {/* <div className="testimonial-carousel dots-right-btm"> */}
                        <Slider {...testimonial_carousel}>
                          {service.all_testimonial.testimonial.map((testi, i) => (
                            <div className="testimonial-item">
                              <div className="testimonial-brief">
                                <p>{testi.content}</p>
                              </div>
                              <h6>
                              {testi.name}, <span>{testi.position}</span>
                              </h6>
                            </div>
                          ))}
                        </Slider>
                      </div>
                      <div className="col-lg-4 col-md-3">
                        <div className="testimonial-image">
                          <img
                            src="/front/images/others/testimonial.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="brand-area pb-115 d-none">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Slider {...settings}>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/1.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/2.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/3.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/4.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/5.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/1.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/4.png" alt="" />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>SUBSCRIBE</h3>
                    <h2 className="h1">NEWSLETTER</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="subscribe">
                    <form action="#">
                      <input
                        type="text"
                        name="subscribe"
                        placeholder="Enter yur email here..."
                      />
                      <button type="submit" value="send">
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
