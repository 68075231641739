import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";


export default function AgentDetails() {
  const url = process.env.REACT_APP_BASE_URL;

  const { slug } = useParams();

  // Agent API
  const [progress, setProgress] = useState(0)
  const [isLoading, setLoading] = useState(true);
  const [agent, setItemsHome] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemshome = async () => {
      setProgress(progress + 40);
      axios
         .get(`${process.env.REACT_APP_BASE_URL}/api/get_agent_by_slug/${slug}`)
        .then(function (resultHome) {
          setProgress(80);
          setItemsHome(resultHome.data);
          setLoading(false);
          setProgress(100);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);
  // Agent API

  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
         <LoadingBar
        color='#f11946'
        progress={progress}
        height={3}
        onLoaderFinished={() => setProgress(0)}
      />
        <div className="breadcrumbs-area bread-bg-1 bg-opacity-black-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumbs">
                  <h2 className="breadcrumbs-title">{agent.name}</h2>
                  <ul className="breadcrumbs-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/agent">Agent</Link>
                    </li>
                    <li>{agent.name}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="page-content" className="page-wrapper">
          <div className="agents-details-about pt-115">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="agent-details-desc-info">
                    <div className="agent-details-name">
                      <h3>{agent.name}</h3>
                      <p>{agent.position}</p>
                    </div>
                    <div className="agent-details-brief">
                      <p>{agent.about_us}</p>
                    </div>
                    <div className="agent-details-biography">
                      <h5>Biography</h5>
                      <p>{agent.biography}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-4">
                      <div className="agent-details-image">
                        <img src={url+agent.image} alt={agent.name} />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-8">
                      <div className="agent-details-contact">
                        <h5>Contact Details</h5>
                        
                        <h6>Phone</h6>
                        <p>
                          <img src="images/icons/phone-2.png" alt="" />
                          {agent.mobile}
                        </p>
                        <h6>Email</h6>
                        <p>
                          <img src="images/icons/mail-close.png" alt="" />
                          {agent.email}
                        </p>
                        <ul className="social-media">
                          <li>
                            <a href={agent.fb_url}>
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a href={agent.tw_url}>
                              <i
                                className="fa fa-twitter"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a href={agent.linkdin_url}>
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a href={agent.telegram_url}>
                              <i
                                className="fa fa-telegram"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="counter-area pt-60 pb-115">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="counter-item-content-2 text-center">
                    {agent.complete_project > 0 ?
                        <div className="counter-item-2">
                        <h2>
                            <i className="fa fa-home" aria-hidden="true"></i>
                            <span className="counter">{agent.complete_project}</span>
                        </h2>
                        <p>Complete Project</p>
                        </div>
                    :
                    <p></p>
                    }
                    {agent.project_sold > 0 ?
                        <div className="counter-item-2">
                        <h2>
                            <i className="fa fa-key" aria-hidden="true"></i>
                            <span className="counter">{agent.project_sold}</span>
                        </h2>
                        <p>Property Sold</p>
                        </div>
                    :
                    <p></p>
                    }
                    {agent.project_sold > 0 ?
                        <div className="counter-item-2">
                        <h2>
                            <i className="fa fa-smile-o" aria-hidden="true"></i>
                            <span className="counter">{agent.project_sold}</span>
                        </h2>
                        <p>Happy Clients</p>
                        </div>
                    :
                    <p></p>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="experience-inquiry-area pb-115">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="experience">
                    <h5>Wroking Experience</h5>
                    <p>
                      Robin Patrick ipsum dolor sit amet, consectetur adipiscing
                      elit, sed domod tempor incididunt ut labore et dolore
                      magna iqua. Ut enim ad minim venia nod exercitation
                      ullamco laboris nisi utev{" "}
                    </p>
                    <div className="author-quote">
                      <p>
                        Lorem is a dummy text do eiusmod tempor dolor sit amet,
                        onsec adip iscing elit, sed do eiusmod tempor incididunt
                        ut labore et lorna aliqua Ut enim onsectetur
                      </p>
                      <p>
                        Lorem is a dummy text do eiusmod tempor dolor sit amet,
                        onsec adip iscing elit, sed do eiusmod tempor incididunt
                        ut labore et lorna
                      </p>
                      <p>
                        Lorem is a dummy text do eiusmod tempor dolor sit amet,
                        onsec adip iscing elit, sed do eiusmod tempor incididunt
                        ut labore et lorna
                      </p>
                      <p>
                        Lorem is a dummy text do eiusmod tempor dolor sit amet,
                        onsec adip iscing elit, sed do eiusmod tempor incididunt
                        ut labore et lorna
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inquery leave-review">
                    <h5>Contact for any Inquiry</h5>
                    <form action="#">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <input
                            type="text"
                            name="name"
                            placeholder="Your name"
                          />
                        </div>
                        <div className="col-lg-6 col-12">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <input type="text" name="phone" placeholder="Phone" />
                      <textarea placeholder="Write here"></textarea>
                      <button type="button" className="submit-btn-1">
                        SEND
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>SUBSCRIBE</h3>
                    <h2 className="h1">NEWSLETTER</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="subscribe">
                    <form action="#">
                      <input
                        type="text"
                        name="subscribe"
                        placeholder="Enter yur email here..."
                      />
                      <button type="submit" value="send">
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
