import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default function PreLoader() {
  return (
    <>
      <div className="breadcrumbs-area bread-bg-1 bg-opacity-black-70">
        <div className="container">
          <div className="row">
            <div className="col-12">
              
            </div>
          </div>
        </div>
      </div>
      <section id="page-content" className="page-wrapper">
        <div className="contact-area pt-115 pb-115">
          <div className="container">
            <div className="row d-flex justify-content-center">
            <Loader
                type="Puff"
                color="gray"
                height={100}
                width={100}
                timeout={3000} //3 secs
            />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
