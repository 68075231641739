import './App.css';
import Layout from './conmonents/Layout';
import {Routes,Route} from "react-router-dom"
import Home from "./conmonents/Home"
import Contact from './conmonents/Contact';
import Service from './conmonents/Services';
import ServiceDetails from './conmonents/ServiceDetails';
import LandingPage from './conmonents/LandingPage';
import Blog from './conmonents/Blog';
import BlogDetails from './conmonents/BlogDetails';
import BlogCategory from './conmonents/BlogCategory';
import Thankyou from './conmonents/Thankyou';
import Agent from './conmonents/Agent';
import AgentDetails from './conmonents/AgentDetails';
import Properties from './conmonents/Properties';
import PropertiesDetails from './conmonents/PropertiesDetails';
import Search from './conmonents/Search';
import AboutUs from './conmonents/AboutUs';

require('dotenv').config();

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home/>} />
          <Route path="about-us" element={<AboutUs/>} />
          <Route path="contact" element={<Contact/>} />
          <Route path="services" element={<Service/>} />
          <Route path="services/:slug" element={<ServiceDetails />} />
          <Route path="blog" element={<Blog/>} />
          <Route path="blog-details/:slug" element={<BlogDetails/>} />
          <Route path="blog-category/:slug" element={<BlogCategory/>} />
          <Route path="our-excutive" element={<Agent/>} />
          <Route path="excutive-details/:slug" element={<AgentDetails/>} />
          <Route path="properties" element={<Properties/>} />
          <Route path="propertie-details/:slug" element={<PropertiesDetails/>} />
          <Route path="search" element={<Search/>} />
        </Route>
        <Route path="lp/:category/:slug" element={<LandingPage/>} />
        <Route path="thankyou/:slug" element={<Thankyou/>} />
      </Routes>
      
    </>
  );
}

export default App;
